body { background-color: transparent; }
body { color: var(--clr-898425); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 32px;

@media #{$medium-up} {
font-size: 44px;

}
}
h2 {
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 24px;

}
}
.me-Quote .quote-author {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
/* Background:2 */
.MES2 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bviq.net/img/330/12');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES2 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bviq.net/img/330/12');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: #ffffff;
 }
 }
cite.MEC2{
color: #ffffff;
}
/* Primary:3 */
.MES3 {
background-color: var(--clr-898426);
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-898426);
 }
.MES4 {
background-color: var(--clr-898426);
 }
/* Light:5 */
.MES5 {
background-color: #ffffff;
 }
/* Light:6 */
.MES6 {
background-color: #ffffff;
 }
.MES6 {
background-color: #ffffff;
 }
/* Secondary:7 */
.MES7 {
background-color: var(--clr-898427);
 }
/* Secondary:8 */
.MES8 {
background-color: var(--clr-898427);
 }
.MES8 {
background-color: var(--clr-898427);
 }
/* Header:9 */
.MES9 {
color: #ffffff;
 }
.MES9 {
color: #ffffff;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
 }
a.MEC9 { color: #ffffff;
 }
cite.MEC9{
color: #ffffff;
}
/* Spacer:10 */
.MES10 {
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
 }
/* Dark:11 */
.MES11 {
background-color: var(--clr-898425);
color: #ffffff;
 }
/* Dark:12 */
.MES12 {
background-color: var(--clr-898425);
color: #ffffff;
 }
/* Dark:13 */
.MES13 {
background-color: var(--clr-898425);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-898425);
color: #ffffff;
 }
cite.MEC13{
color: #ffffff;
}
/* Dark:14 */
.MES14 {
background-color: var(--clr-898425);
color: #ffffff;
 }
.MES14 {
background-color: var(--clr-898425);
color: #ffffff;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
cite.MEC14{
color: #ffffff;
}
/* Shade 1:15 */
.MES15 {
background-color: var(--clr-898428);
 }
/* Shade 1:16 */
.MES16 {
background-color: var(--clr-898428);
 }
.MES16 {
background-color: var(--clr-898428);
 }
/* Enquiry Button:17 */
.MES17 {
background-color: var(--clr-898429);
&:hover {background-color: var(--clr-898426);}
color: #ffffff;
border-radius: 50px;
border-width: 3px;
border-style: solid;
border-color: #ffffff;
 }
/* Secondary page:19 */
.MES19 {
background-color: var(--clr-898431);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bviq.net/img/322/246');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}padding: 80px 10px 15px 10px;

 }
.MES19 {
background-color: var(--clr-898431);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bviq.net/img/322/246');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}padding: 80px 10px 15px 10px;

 }
/* Medium Button:20 */
.MES20 {
color: var(--clr-898431);
&:hover { color: var(--clr-898426);}
border-radius: 50px;
border-width: 2px;
border-style: solid;
border-color: var(--clr-898431);
&:hover { border-color: var(--clr-898426); }
 }
/* Org List:21 */
details.MES21 {
& > summary{background-color: var(--clr-898431);
}
& > summary{padding: 10px;}

& > article {border-width: 0 0 0 3px;
border-color: var(--clr-898431);
}& > summary { color: #ffffff;
font-size:16.8px;
@media #{$medium-up} {
font-size:20.8px;
}
@media #{$large-up} {
font-size:22.4px;
}
 }
 }
/* footer block:22 */
.MES22 {
background-color: var(--clr-898428);
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
 }
.MES22 {
background-color: var(--clr-898428);
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
 }
cite.MEC22{
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
}
/* Report:23 */
details.MES23 {
& > summary{background-color: #ffffff;
}
& > summary { font-size:21px;
@media #{$medium-up} {
font-size:24px;
}
 }
 }
/* Obs Total:24 */
.MES24 {
background-color: #ffffff;
border-radius: 40px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES24 {
background-color: #ffffff;
border-radius: 40px;
-webkit-transform: translateZ(0);
overflow: hidden;
h1.MEC24 { font-size: 64px; }
h2.MEC24 { font-size: 44px; }
h3.MEC24 { font-size: 40px; }
h4.MEC24 { font-size: 36px; }
h5.MEC24 { font-size: 34px; }
h6.MEC24 { font-size: 32px; }
 }
/* Search Button:25 */
.MES25 {
color: var(--clr-898431);
&:hover { color: var(--clr-898426);}
border-radius: 5px;
padding: 5px;

border-width: 3px;
border-style: solid;
border-color: var(--clr-898431);
&:hover { border-color: var(--clr-898426); }
 }
/* Bold Line:26 */
.MES26 {
& > hr {border-width: 5px 0 0 0;}
& > hr {border-color: var(--clr-898425);}
& > hr {border-top-style: solid;}
 }
/* Top Menu:28 */
nav.me-Menu.MES28 {
& .menu-item.MEC28, & .menu-item.MEC28 > div.MEC28{ & > a.MEC28{color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.94px;
}@media #{$large-up} {
font-size: 17.5px;
}text-transform: uppercase;
}
 &:hover > a.MEC28{color: var(--clr-898428);
}
 }
&.horizontal > .menu-item.MEC28 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC28 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC28 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC28 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 15px;}

& .sub-menu .menu-item a{padding: 0 15px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Top Background:29 */
.MES29 {
background-color: var(--clr-898425);
& > .underlay, & > .inner-overlay { opacity: 0.8 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bviq.net/img/1760642/449832');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 23.68px;
};
min-height:324px;
@media #{$medium-up} {
min-height: 251px;};
@media #{$large-up} {
min-height: 585px;};
 }
.MES29 {
background-color: var(--clr-898425);
& > .underlay, & > .inner-overlay { opacity: 0.8 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bviq.net/img/1760642/449832');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 23.68px;
};
min-height:324px;
@media #{$medium-up} {
min-height: 251px;};
@media #{$large-up} {
min-height: 585px;};
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
h1.MEC29 { @media #{$medium-up} { font-size: 53.68px; }; }
h1.MEC29 { @media #{$large-up} { font-size: 66px; }; }
h2.MEC29 { @media #{$medium-up} { font-size: 42.7px; }; }
h2.MEC29 { @media #{$large-up} { font-size: 52.5px; }; }
h3.MEC29 { @media #{$medium-up} { font-size: 36.6px; }; }
h3.MEC29 { @media #{$large-up} { font-size: 45px; }; }
h4.MEC29 { @media #{$medium-up} { font-size: 30.5px; }; }
h4.MEC29 { @media #{$large-up} { font-size: 37.5px; }; }
h5.MEC29 { @media #{$medium-up} { font-size: 24.4px; }; }
h5.MEC29 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC29 { @media #{$medium-up} { font-size: 21.96px; }; }
h6.MEC29 { @media #{$large-up} { font-size: 27px; }; }
 }
cite.MEC29{
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 23.68px;
};
}
/* Page Header:30 */
.MES30 {
background-color: var(--clr-898432);
padding: 10px;

 }
.MES30 {
background-color: var(--clr-898432);
padding: 10px;

 }
/* Home Header:32 */
.MES32 {
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 28.96px;
};
 }
.MES32 {
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 28.96px;
};
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: #ffffff;
 }
h1.MEC32 { @media #{$large-up} { font-size: 74.36px; }; }
h2.MEC32 { @media #{$large-up} { font-size: 59.15px; }; }
h3.MEC32 { @media #{$large-up} { font-size: 50.7px; }; }
h4.MEC32 { @media #{$large-up} { font-size: 42.25px; }; }
h5.MEC32 { @media #{$large-up} { font-size: 33.8px; }; }
h6.MEC32 { @media #{$large-up} { font-size: 30.42px; }; }
 }
a.MEC32 { color: #ffffff;
 }
cite.MEC32{
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 28.96px;
};
}
/* Home Text:33 */
.MES33 {
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 27.68px;
};
 }
.MES33 {
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 27.68px;
};
h1.MEC33 { font-size: 25.92px; }
h1.MEC33 { @media #{$large-up} { font-size: 44px; }; }
h2.MEC33 { font-size: 17.82px; }
h2.MEC33 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC33 { font-size: 16.2px; }
h3.MEC33 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC33 { font-size: 14.58px; }
h4.MEC33 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC33 { font-size: 13.77px; }
h5.MEC33 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC33 { font-size: 12.96px; }
h6.MEC33 { @media #{$large-up} { font-size: 18px; }; }
 }
.MEC33 li { 
@media #{$medium-up} {
font-size: 18.56px;

}
@media #{$large-up} {
font-size: 26.24px;

}
 }
cite.MEC33{
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 27.68px;
};
}
/* Chart Box:34 */
.MES34 {
background-color: #ffffff;
border-radius: 24px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 24px;

border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES34 {
background-color: #ffffff;
border-radius: 24px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 24px;

border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Home Text (Smaller):35 */
.MES35 {
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 19.04px;
};
 }
.MES35 {
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 19.04px;
};
h1.MEC35 { font-size: 25.92px; }
h1.MEC35 { @media #{$large-up} { font-size: 39.6px; }; }
h2.MEC35 { font-size: 17.82px; }
h2.MEC35 { @media #{$large-up} { font-size: 31.5px; }; }
h3.MEC35 { font-size: 16.2px; }
h3.MEC35 { @media #{$large-up} { font-size: 27px; }; }
h4.MEC35 { font-size: 14.58px; }
h4.MEC35 { @media #{$large-up} { font-size: 22.5px; }; }
h5.MEC35 { font-size: 13.77px; }
h5.MEC35 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC35 { font-size: 12.96px; }
h6.MEC35 { @media #{$large-up} { font-size: 16.2px; }; }
 }
.MEC35 li { 
@media #{$medium-up} {
font-size: 18.56px;

}
@media #{$large-up} {
font-size: 26.24px;

}
 }
cite.MEC35{
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 19.04px;
};
}
/* Vessel Header:36 */
.MES36 {
background-color: var(--clr-898433);
color: #ffffff;
 }
.MES36 {
background-color: var(--clr-898433);
color: #ffffff;
 }
cite.MEC36{
color: #ffffff;
}
/* Mer Header:37 */
details.MES37 {
& > summary{background-color: var(--clr-898434);
}
& > summary{padding: 10px;}

& > summary { color: var(--clr-898425);
font-size:15.4px;
@media #{$medium-up} {
font-size:17.6px;
}
 }
 }
/* Vessel Header:38 */
details.MES38 {
& > summary { font-size:14.98px;
@media #{$medium-up} {
font-size:17.12px;
}
 }
 }
/* MER Innovate Header:39 */
.MES39 {
background-color: var(--clr-1029780);
 }
.MES39 {
background-color: var(--clr-1029780);
 }
/* Blue:40 */
.MES40 {
background-color: #06086282;
 }
.MES40 {
background-color: #06086282;
 }
/* Solutions Blue:41 */
.MES41 {
background-color: #0063f27e;
 }
.MES41 {
background-color: #0063f27e;
 }
/* Grey Stone:42 */
.MES42 {
background-color: #f3f3f3;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-1029790);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES42 {
background-color: #f3f3f3;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-1029790);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Turqoise Panel:43 */
.MES43 {
background-color: var(--clr-1029791);
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: #e4e3de;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES43 {
background-color: var(--clr-1029791);
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: #e4e3de;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Grey Outline:44 */
.MES44 {
background-color: #ffffff;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-1029790);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES44 {
background-color: #ffffff;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-1029790);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Open Button:45 */
.MES45 {
background-color: var(--clr-1029780);
color: #ffffff;
font-size: 16.16px;
@media #{$medium-up} {
font-size: 19.98px;
};
@media #{$large-up} {
font-size: 19.8px;
};
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
 }
/* New Request button:46 */
.MES46 {
background-color: transparent;
&:hover {background-color: #ffffff85;}
color: #ffffff;
&:hover { color: var(--clr-1029780);}
border-radius: 50px;
border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Reject Button:47 */
.MES47 {
background-color: var(--clr-1029791);
color: #ffffff;
font-size: 16.16px;
@media #{$medium-up} {
font-size: 19.98px;
};
@media #{$large-up} {
font-size: 19.8px;
};
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
 }
/* Line panel Grey:48 */
.MES48 {
background-color: #ffffff;
border-width: 1px;
border-style: solid;
border-color: var(--clr-898428);
 }
.MES48 {
background-color: #ffffff;
border-width: 1px;
border-style: solid;
border-color: var(--clr-898428);
 }
/* File Panel:49 */
.MES49 {
background-color: transparent;
color: #000000;
&:hover { color: var(--clr-1029782);}
border-width: 1px;
border-style: solid;
border-color: #c0c0c0;
 }
/* Circle:50 */
.MES50 {
background-color: #66bb6a;
color: #ffffff;
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES50 {
background-color: #66bb6a;
color: #ffffff;
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
cite.MEC50{
color: #ffffff;
}
/* Finalise Button:51 */
.MES51 {
background-color: var(--clr-1029789);
&:hover {background-color: var(--clr-1029790);}
color: #000000;
border-radius: 50px;
border-width: 3px;
border-style: solid;
border-color: #ffffff;
 }
/* Page Accordion:52 */
details.MES52 {
 }
